import PropTypes from "prop-types"
import React, { Component } from "react"

import colors from "../../utils/colors"

import {
  Brand,
  CloseIcon,
  Container,
  Content,
  HumbergerMenu,
  LargeMenu,
  Link,
  Logo,
  MenuLink,
  NavBar,
  OpenIcon,
  SmallMenu,
} from "./style"

class Header extends Component {
  state = {
    showMenu: false,
  }

  handleClick = () =>
    this.setState(({ showMenu }) => ({
      showMenu: !showMenu,
    }))

  render() {
    const { menuLinks } = this.props
    const { showMenu } = this.state

    const menu = (
      <>
        {menuLinks.map(link => (
          <MenuLink key={link.name}>
            <Link
              to={link.link}
              aria-label={link.name}
              activeStyle={{ color: colors.green }}
            >
              {link.name}
            </Link>
          </MenuLink>
        ))}
      </>
    )

    return (
      <Container>
        <Content>
          <Brand>
            <Link aria-label="Logo" to="/">
              <Logo />
            </Link>
          </Brand>
          <HumbergerMenu>
            {showMenu ? (
              <CloseIcon onClick={this.handleClick} />
            ) : (
              <OpenIcon onClick={this.handleClick} />
            )}
          </HumbergerMenu>
          <NavBar>
            <LargeMenu>{menu}</LargeMenu>
            {showMenu ? <SmallMenu>{menu}</SmallMenu> : null}
          </NavBar>
        </Content>
      </Container>
    )
  }
}

Header.propTypes = {
  menuLinks: PropTypes.array.isRequired,
}

Header.defaultProps = {
  menuLinks: [],
}

export default Header
