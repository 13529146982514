import React from "react"

import { Container, Content, Copyright, Info, Column, Row } from "./style"

const Footer = () => (
  <Container>
    <Info>
      <Content>
        <Row>
          <Column>
            <h4>Adresse</h4>
            <a href="http://maps.google.com/?q=GAEC L'abbaye d'en bas 56800 CAMPÉNÉAC">
              <p>9, l'abbaye d'en bas</p>
              <p>56800 CAMPÉNÉAC</p>
            </a>
          </Column>
          <Column>
            <h4>Téléphone</h4>
            <a href="tel:+33297934650">
              <p>0297934650</p>
            </a>
          </Column>
          <Column>
            <h4>Les marchés</h4>
            <p>Vendredi matin - PLOËRMEL (56)</p>
            <p>Samedi matin - VANNES (56)</p>
          </Column>
        </Row>
      </Content>
    </Info>
    <Copyright>
      <Content>
        Copyright © 2014 - {new Date().getFullYear()} GAEC L'abbaye d'en bas
      </Content>
    </Copyright>
  </Container>
)

export default Footer
