import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

import Close from "../../assets/close.svg"
import Goat from "../../assets/logo.svg"
import Menu from "../../assets/menu.svg"

import { size } from "../../utils/devices"
import colors from "../../utils/colors"

export const Container = styled.header`
  background-color: ${colors.black};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-top: 3px solid ${colors.green};
`

export const Content = styled.div`
  display: flex;
  text-transform: uppercase;
  min-height: 70px;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1.0875rem;

  @media (max-width: ${size.laptop}) {
    height: 100%;
    flex-direction: column;
  }
`

export const Link = styled(GatsbyLink)`
  color: ${colors.grey};
  text-decoration: none;

  &:hover {
    color: ${colors.white};
  }
`

export const Brand = styled.div`
  height: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const HumbergerMenu = styled.div`
  position: absolute;
  right: 25px;
  padding-top: 10px;
  color: ${colors.grey};

  & > * {
    height: 45px;
  }

  @media (min-width: ${size.laptop}) {
    display: none;
  }
`

export const Logo = styled(Goat)`
  width: auto;
  height: 50px;
  cursor: pointer;
  fill: ${colors.green};
`

export const MenuLink = styled.li`
  list-style-type: none;
  font-size: 15px;
  letter-spacing: 1px;
  height: 100%;
  margin: 0;
  align-items: center;
  display: flex;
  margin-left: 1.8rem;

  @media (max-width: ${size.laptop}) {
    margin: 0.45rem 0;
  }
`

export const SmallMenu = styled.ul`
  display: none;
  color: ${colors.white};

  @media (max-width: ${size.laptop}) {
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0;
    height: 100%;
    flex-direction: column;
  }
`

export const LargeMenu = styled.ul`
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  height: 100%;

  @media (max-width: ${size.laptop}) {
    display: none;
  }
`

export const CloseIcon = styled(Close)`
  width: 30px;
  height: 50px;
  fill: ${colors.white};
  cursor: pointer;

  & > * {
    fill: ${colors.grey};
  }

  &:hover {
    & > * {
      fill: ${colors.white};
    }
  }
`

export const OpenIcon = styled(Menu)`
  width: 30px;
  height: 50px;
  cursor: pointer;

  & > * {
    fill: ${colors.grey};
  }

  &:hover {
    & > * {
      fill: ${colors.white};
    }
  }
`

export const NavBar = styled.nav`
  @media (min-width: ${size.laptop}) {
    margin-left: auto;
  }
`
