import PropTypes from "prop-types"
import React from "react"

import { Container, Left, Right, Image, TitleRight, TitleLeft } from "./style"

const Item = ({ image, title, text, left }) => (
  <Container left={left}>
    {left ? (
      <>
        <Image fluid={image.childImageSharp.fluid} />
        <Left>
          <TitleLeft>{title}</TitleLeft>
          {text}
        </Left>
      </>
    ) : (
      <>
        <Right>
          <TitleRight>{title}</TitleRight>
          {text}
        </Right>
        <Image fluid={image.childImageSharp.fluid} />
      </>
    )}
  </Container>
)

Item.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  left: PropTypes.bool,
}

Item.defaultProps = {
  left: false,
}

export default Item
