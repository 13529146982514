import styled from "styled-components"
import Img from "gatsby-image"

import { size } from "../../utils/devices"

export const Container = styled.div`
  display: flex;
  margin-bottom: 1.8rem;

  @media (max-width: ${size.laptop}) {
    flex-direction: ${({ left }) => (left ? "column-reverse" : "column")};
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  text-align: justify;

  @media (max-width: ${size.laptop}) {
    margin: 0;
  }
`

export const Left = styled(Text)`
  margin-left: 1.8rem;
`

export const Right = styled(Text)`
  margin-right: 1.8rem;
`

export const Image = styled(Img)`
  flex: 1;
  border-radius: 2px;
`

export const TitleLeft = styled.h3`
  text-align: left;
`

export const TitleRight = styled.h3`
  text-align: right;

  @media (max-width: ${size.laptop}) {
    text-align: left;
  }
`
